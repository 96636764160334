import React from "react";

/* Location
============================================================================= */

const Location = () => {
	return (
		<svg className="location" viewBox="0 0 18.92 25">
			<path
				style={{ fill: "#db2735" }}
				d="M12.51,25a1.31,1.31,0,0,1-.38-.06l-.23-.06-.34-.31c-.24-.24-1.05-1.17-1.63-1.86-.79-.95-1.63-2-2.3-3C5,16.1,3.46,13,3.12,10.54a9.32,9.32,0,0,1,1-5.45A9.48,9.48,0,0,1,12,0a9.8,9.8,0,0,1,3.31.4A9.48,9.48,0,0,1,21.7,7.2h0A9.09,9.09,0,0,1,22,9.41a8.36,8.36,0,0,1-.23,2c-.69,2.91-3,6.83-6.45,11.05,0,0-1.69,2-2,2.3A1.23,1.23,0,0,1,12.51,25Zm0-23.15h-.41a7.59,7.59,0,0,0-7.13,8.43c.3,2.12,1.75,5,4.18,8.38.66.9,1.47,1.94,2.23,2.86.36.43.82,1,1.15,1.34.35-.39.87-1,1.35-1.58,3.3-4,5.46-7.66,6.08-10.3a6.42,6.42,0,0,0,.19-1.58,7,7,0,0,0-.22-1.77h0a7.6,7.6,0,0,0-5.17-5.46A7.76,7.76,0,0,0,12.48,1.85Zm0,11.81h-.25a5,5,0,0,1-1.53-.31,8.34,8.34,0,0,1-.9-.44A5.08,5.08,0,0,1,7.89,6.49,4.88,4.88,0,0,1,9.05,4.88,5,5,0,0,1,12,3.55a6.76,6.76,0,0,1,1.19,0,5.06,5.06,0,0,1,2.15.81,5,5,0,0,1,2.11,3,4.51,4.51,0,0,1,.15,1.23,3.8,3.8,0,0,1-.07.85h0a5.07,5.07,0,0,1-5,4.22Zm-.07-8.29h-.26a3.2,3.2,0,0,0-1.89.86,3.32,3.32,0,0,0-.72,1,3.25,3.25,0,0,0-.08,2.47,3.29,3.29,0,0,0,1.31,1.61,5,5,0,0,0,.57.28,3.23,3.23,0,0,0,1,.2,3.22,3.22,0,0,0,3.34-2.67,2.37,2.37,0,0,0,0-.55,2.86,2.86,0,0,0-.09-.79,3.16,3.16,0,0,0-1.35-1.89,3,3,0,0,0-1.37-.52Z"
				transform="translate(-3.04 0)"
			/>
		</svg>
	);
};

/* Phone
============================================================================= */

const Phone = () => {
	return (
		<svg className="phone" viewBox="0 0 25 25">
			<path
				style={{ fill: "#db2735" }}
				d="M18.71,25c-3.47,0-8.26-2.55-12.21-6.5S0,9.75,0,6.29A4.19,4.19,0,0,1,.87,3.45L3,1A2.44,2.44,0,0,1,5,0H5A2.85,2.85,0,0,1,7,.92l3.41,3.41a2.62,2.62,0,0,1,.92,2,2.66,2.66,0,0,1-.92,2L9.18,9.49A.72.72,0,0,0,9,9.74L15.23,16a.5.5,0,0,0,.29-.16l1.23-1.23a2.55,2.55,0,0,1,3.92,0L24.08,18A2.71,2.71,0,0,1,25,20a2.47,2.47,0,0,1-1,2l-2.48,2.17A4.19,4.19,0,0,1,18.71,25ZM5.07,2c-.25,0-.26,0-.5.27L2.4,4.79A2.43,2.43,0,0,0,2,6.29C2,8.63,3.88,13,7.94,17.06S16.37,23,18.71,23a2.5,2.5,0,0,0,1.51-.37l2.47-2.17c.27-.23.27-.25.28-.5a1,1,0,0,0-.33-.5L19.23,16c-.32-.33-.41-.33-.52-.33s-.22,0-.52.32l-1.25,1.25a2.19,2.19,0,0,1-2.72.5L14,17.57,7.24,10.78a2.2,2.2,0,0,1,.49-2.71L9,6.82c.3-.3.32-.41.32-.53s0-.2-.32-.52L5.57,2.35A.93.93,0,0,0,5.07,2Z"
				transform="translate(0)"
			/>
		</svg>
	);
};

/* Email
============================================================================= */

const Email = () => {
	return (
		<svg className="email" viewBox="0 0 25 18.85">
			<path
				style={{ fill: "#db2735" }}
				d="M3,4.82l8.79,8.89a1.12,1.12,0,0,0,.79.3h0a1.19,1.19,0,0,0,.8-.29l8.84-8.9Zm14,7.7,6.26,6.41V6.21ZM1.74,6.06V18.93l6.33-6.46ZM3,20.18H22l-6.28-6.43L14.57,15a2.88,2.88,0,0,1-2,.81h0a2.87,2.87,0,0,1-2-.83L9.3,13.71Zm22,1.75H0V3.07H25Z"
				transform="translate(0 -3.07)"
			/>
		</svg>
	);
};

/* Export
============================================================================= */

export { Location, Phone, Email };
