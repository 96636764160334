import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Logo from "../svg/logo";
import BackgroundLogo from "../svg/backgroundLogo";
import { Location, Phone, Email } from "../svg/contact";

/* Home Page
============================================================================= */

const HomePage = () => (
	<Layout>
		<SEO title="Home" />

		<div className="placeholder-container">
			<Logo />
			<h2 className="under-construction">
				Full Website Under Construction
			</h2>
			<div className="contact-details">
				<div className="contact-details-item">
					<Location />
					<p>
						20 Bassendean Road, <br /> Bayswater WA 6053
					</p>
				</div>
				<div className="contact-details-item">
					<Phone />
					<p>
						0450 248 464 <br /> 08 9371 9944
					</p>
				</div>
				<div className="contact-details-item">
					<Email />
					<a
						href="mailto: info@atlaspremiumpest.com.au"
						style={{ color: "black", textDecoration: "underline" }}>
						<p>info@atlaspremiumpest.com.au</p>
					</a>
				</div>
			</div>
			<div className="color-bar-container">
				<div
					className="color-bar"
					style={{
						transformOrigin: "100% 0%",
					}}
				/>
				<div style={{ width: "15px" }} />
				<div
					className="color-bar"
					style={{
						backgroundColor: "#db2735",
						transformOrigin: "0% 0%",
					}}
				/>
			</div>
		</div>
		<div className="background-logo-container">
			<BackgroundLogo />
		</div>
	</Layout>
);

export default HomePage;
