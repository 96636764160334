import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Header = ({ siteTitle }) => (
	<header className="header">
		<div />
	</header>
);

/* Export
============================================================================= */

export default Header;
